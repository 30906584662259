import styled from "styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { device } from "../../../../styles/mixins";

export const DrawerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin: -5px -5px -5px 0px;
  height: 40px;
  width: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  border-radius: 40px;
  background-color: rgba(250, 250, 250, 0.3);
  z-index: 1;
  margin-left: auto;
  margin-bottom: 4px;

  svg {
    height: 100%;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -0.125em;
    width: 100%;
    padding: 7px;
  }

  &:hover {
    color: rgb(43, 42, 45);
    background-color: rgb(247, 247, 248);
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const MenuStyled = styled(DropdownMenu.Content)`
  min-width: 220px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 6px;
  padding: 15px 5px;
  box-shadow: 0px 10px 38px -10px rgb(22 23 24 / 35%),
    0px 10px 20px -15px rgb(22 23 24 / 20%);
  margin-top: 16px;
  font-family: "Raleway", sans-serif !important;
  a {
    font-size: 18px;
    line-height: 1.5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 5px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #5b5f72;

    display: block;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    &:hover {
      background-color: rgba(161, 175, 160, 0.3);
    }
  }
`;
