// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-post-post-jsx": () => import("./../../../src/templates/Post/Post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-service-service-jsx": () => import("./../../../src/templates/Service/Service.jsx" /* webpackChunkName: "component---src-templates-service-service-jsx" */)
}

