import * as LabelPrimitive from '@radix-ui/react-label';
import * as Separator from '@radix-ui/react-separator';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../../styles/mixins';
// import SocialIcons from "../socialIcons";

export const formField = css`
  box-sizing: border-box;
  margin: 0;
  padding: 10px 10px;
  font-family: inherit;
  font-size: inherit;
  color: var(--color__primary-dark);
  background-color: white;
  border: none;
  outline: none;
  line-height: 1;
  flex: 1 0 auto;
  border-radius: 4px;
  box-shadow: var(--color__primary-light) 0px 0px 0px 1px;
  transition: all 0.5s ease;

  &:focus {
    box-shadow: var(--color__primary) 0px 0px 0px 2px;
  }
`;

export const ContactStyles = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-direction: column;

  ${mediaQuery.sm`
      flex-direction: row;
  `}

  textarea {
    ${formField}

    padding-top: 10px;
    transition: height 0.5s ease;

    ${mediaQuery.md`
      height: ${(props) => (props.isFormFocused ? '112px' : '32px')};
    `}
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 48px 0 32px;
    font-color: var(--color__primary-dark);
  }
`;

export const ContactForm = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--lightGrey);
  /* background-color: rgb(232, 237, 234); */
  ${mediaQuery.lg`
      width: 40%;
      // background-color: #f2f5f4;
  `}

  display: flex;

  border-radius: 4px;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 10px;
  font-family: Untitled Sans, -apple-system, system-ui, sans-serif;
  padding: 20px;

  ${mediaQuery.md`
  height: ${(props) => (props.isFormFocused ? 'auto' : '460px')};

  `}
  transition: height 0.5s ease;
`;

export const ContactInfo = styled.div`
  padding-top: 24px;

  ${mediaQuery.md`
  width: 50%;
  `}
  font-family: Untitled Sans, -apple-system, system-ui, sans-serif;
`;

export const Button = styled.button`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 500;
  height: 35px;

  margin-left: auto;

  background-color: var(--color__primary-light); // Color del boton
  color: var(--color__primary); // Color del texto del boton

  &:hover {
    background-color: #re7b39d; // Color seleccionado mirar se funciona bien
  }

  &:focus {
    box-shadow: var(--color__primary) 0px 0px 0px 2px;
  }

  ${mediaQuery.md`
   opacity: ${(props) => (props.isFormFocused ? '1' : '0')};
  transition: opacity 0.2s ease;
  transition-delay: ${(props) => (props.isFormFocused ? '0.2s' : '0')};
  `}
`;

export const Fieldset = styled.fieldset`
  all: unset;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Label = styled(LabelPrimitive.Root)`
  line-height: 1;
  margin-bottom: 10px;
  color: var(--color__secondary-dark);
  display: inline-block;
  font-size: 15px;
  align-items: center;

  a {
    font-size: 22px; /* Adjust font size for the link */
    color: blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline; /* Optional: underline the link on hover */
    }
  }
  &.terms-label {
    font-size: 22px; /* Adjust the font size for this specific label */
  }
`;

export const Input = styled.input.attrs((props) => ({
  type: props.type || 'text', // Default to text if no type is provided
}))`
  ${formField};
  height: 35px;

  /* Specific styles for checkbox */
  &[type='checkbox'] {
    /* Explicitly set height and width */
    height: 22px !important;
    width: 22px !important;
    margin-right: 15px;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    box-sizing: border-box;
  }
`;

export const Text = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  color: #6f6e77;
  font-size: 15px;
  line-height: 1.5;
`;

export const LinkedIn = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  color: ${({ isService, isHero, isBlog }) =>
    isService || isBlog
      ? '#fac69d'
      : isHero
      ? 'var(--color__grey)'
      : '#798578'};
  cursor: pointer;
  width: fit-content;

  &,
  path {
    transition: all 0.5s ease;
  }

  &:hover {
    ${({ isService, isHero }) =>
      isService || isHero
        ? css`
            transform: scale(0.9);
          `
        : css`
            color: var(--color__primary-dark);
            path:last-of-type {
              fill: var(--color__primary-dark);
            }
          `}
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Telephone = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  color: #798578;
  cursor: pointer;
  width: fit-content;

  &,
  path {
    transition: all 0.5s ease;
  }

  &:hover {
    color: var(--color__primary-dark);
    path:last-of-type {
      fill: var(--color__primary-dark);
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ContactSeparator = styled(Separator.Root)`
  background-color: #1e70695e;
  &[data-orientation='horizontal'] {
    height: 3px;
    width: 50%;
  }
  &[data-orientation='vertical'] {
    height: 100%;
    width: 1px;
  }
`;
