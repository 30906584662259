import * as React from 'react';
import '../../styles/reset.css';
import GlobalStyles from '../../styles/GlobalStyles';
import Footer from '../Footer';
import Header from '../Header';
import Hero from '../Hero';
import incendio from '../../assets/images/incendio.jpg';
// Import Styled Components Global
// Import SEO

const Layout = ({ children, className, location, ...props }) => {
  const pageGroup = props.pageContext?.group || null;
  const pagePath = location.pathname !== '/' ? location.pathname : 'home';

  const isService = props.pathContext.group === 'servicio';
  const isPost = props.pathContext.group === 'blog';
  const isBlog = pagePath === '/blog' || pagePath === '/blog/';
  const isProyectos = pagePath === '/nosotros' || pagePath === '/nosotros/';
  const isTerms = pagePath === '/terms' || pagePath === '/terms/';

  const imageUrl = isService
    ? props.data.service.image?.asset.url
    : isPost
    ? props.data.post.image?.asset.url
    : isProyectos
    ? incendio // Use the pathing or directly the foto. No need to use <img >
    : null;

  return (
    <>
      <GlobalStyles />
      <Header
        activePage={pageGroup || pagePath}
        isBlog={isBlog || isPost || isTerms}
      />
      <Hero
        activePage={pageGroup || pagePath}
        data={props?.data}
        image={imageUrl}
        isProyectos={isProyectos}
        isPost={isPost}
        isTerms={isTerms}
      />
      {children}
      <Footer />
      {/* SEO */}
    </>
  );
};

export default Layout;
