import styled, { keyframes, css } from "styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Link } from "gatsby";
const slideUpAndFade = keyframes`
  0% { 
    opacity: 0;
    transform: translateY(2px);
  },
  100% { 
    opacity: 1;
    transform: translateY(0);
    },
`;

const slideRightAndFade = keyframes`
  0% { 
    opacity: 0; 
    transform: translateX(-2px); 
  };
  100% { 
    opacity: 1; 
    transform: translateX(0); 
  };
`;

const slideDownAndFade = keyframes`
  0% { 
    opacity: 0; 
    transform: translateY(-2px);
  };
  100% { 
    opacity: 1; 
    transform: translateY(0);
  };
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px); };
  100% { opacity: 1; transform: translateX(0); };
`;

const itemStyles = css`
  font-family: "Raleway", sans-serif !important;

  all: unset;
  font-size: 18px;
  line-height: 1.5;

  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  position: relative;

  user-select: none;
  color: #5b5f72;
`;

export const SubmenuStyled = styled(DropdownMenu.Content)`
  overflow: hidden;
  min-width: 220px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 6px;
  padding: 15px 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  margin-top: 8px;

  &[data-state="open"] {
    &[data-side="top"] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side="right"] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side="bottom"] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side="left"] {
      animation-name: ${slideRightAndFade};
    }
  }
`;
export const LinkStyled = styled(Link)`
  ${itemStyles};
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 25px;
  cursor: pointer;

  &:hover {
    background-color: rgba(161, 175, 160, 0.3);
  }
`;

const headerColors = {
  service: {
    color: "white",
    backgroundColor: "rgba(255,255,255, 0.25)",
    hoverColor: "white",
  },
  home: {
    color: "white",
    backgroundColor: "rgba(255,255,255, 0.25)",
    hoverColor: "white",
  },
};

export const TriggerItemStyled = styled(DropdownMenu.Trigger)`
  ${itemStyles}
  color: ${"white"}; // Used to set up the color of servicios
  /*
  color: ${({ isService, isBlog }) =>
    isService || isBlog ? headerColors.service.color : headerColors.home.color};
  */
  padding-left: 0;
  font-size: 18px;
  background-color: transparent;
  &[data-state="open"],
  &:focus {
    color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.service.color
        : headerColors.home.color};
    background-color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.service.backgroundColor
        : headerColors.home.backgroundColor};
    border-color: currentColor;
  }
  height: 25px;
  padding: 0.5rem 1.5rem 0.4rem;
  display: block;
  font-weight: 500;
  transition: all 0.2s ease;
  line-height: 1.5;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: var(--borderRadius);
  cursor: pointer;

  :not(.Logo):not(.SimpleLink):hover {
    color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.service.color
        : headerColors.home.color};
    background-color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.service.backgroundColor
        : headerColors.home.backgroundColor};
    border-color: currentColor;
  }
`;

export const StyledArrow = styled(DropdownMenu.Arrow)`
  fill: white;
`;
