import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import {
  SubmenuStyled,
  TriggerItemStyled,
  LinkStyled,
  StyledArrow,
} from "./Submenu.styled";

const Submenu = ({ services, isService, isBlog }) => (
  <DropdownMenu.Root>
    <TriggerItemStyled isService={isService} isBlog={isBlog}>
      Servicios
    </TriggerItemStyled>
    <SubmenuStyled>
      {services.map((service, i) => (
        <DropdownMenu.Item asChild key={i}>
          <LinkStyled to={`/servicio/${service.value}`}>
            {" "}
            {service.label}
          </LinkStyled>
        </DropdownMenu.Item>
      ))}
      <StyledArrow />
    </SubmenuStyled>
  </DropdownMenu.Root>
);

export default Submenu;
