import { createGlobalStyle, css } from "styled-components";
import { mediaQuery } from "./mixins";

// #384647

const GlobalStyles = createGlobalStyle`
  /* Change font */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');


  :root {
    --primary: #264653;            //
    --primaryLight: #2a9d8f;      
    --secondary: #e2b33c;        
    --secondaryLight: #05752E;   
    ${"" /* --secondaryLight: #e9c46a; */}
    --lightGrey: #f2f2f2;    // Ignorar los colores son referencias a grises
    --midGrey: #e0e0e0;
    --midDarkGrey: #bdbdbd;
    --darkGrey: #828282;     // Aquí termina la referencia de grises
    --danger: #c74817;       // Color de alguna acción peligrosa
    --font-primary: "Montserrat",sans-serif;  // Esta es la fuente principal mirar si es necesario cambiarlo en otros casos
    --font-secondary: "Caveat",cursive;
    --font-system: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif;
    --borderRadius: 20px;

    // COLORS
    --color__primary: #ff914D;
    --color__primary-dark: #8a5039;
    --color__primary-mid: #b5938a;
    --color__primary-light: #f1d3c6;
    --color__primary-alt: #d8c3af;

    --color__secondary: #185464;
    --color__secondary-dark: #2f4858;
    --color__secondary-mid: #677798;
    --color__secondary-light: #fac69d;
    --color__secondary-alt: #8685ef;

    --color__black: #000000;    //Negro puro
    --color__dark: #2d3142;
    --color__grey: #5b5f72;
    --color__light: #e8efff;
    --color__white: #ffffff;    //Blanco puro
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: var(--color__dark);
    
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .section {
  width: 100%;
  padding: 3vw 0;

  ${mediaQuery.lg`
      padding: 90px 60px 140px;
  `}
}
`;
export const container = css`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
`;

export default GlobalStyles;
