import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { DrawerStyled, MenuStyled } from "./Drawer.styled";
import { Link } from "gatsby";

const Drawer = ({ links, services }) => (
  <DrawerStyled>
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M16 128h416c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v32c0 8.84 7.16 16 16 16zm480 96H80c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-64 160H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
          ></path>
        </svg>
      </DropdownMenu.Trigger>

      <MenuStyled onCloseAutoFocus>
        <DropdownMenu.Item asChild>
          <Link to="/#services">Servicios</Link>
        </DropdownMenu.Item>
        {links.map((link, i) => (
          <DropdownMenu.Item asChild key={i}>
            <Link to={link.url}>{link.text} </Link>
          </DropdownMenu.Item>
        ))}
      </MenuStyled>
    </DropdownMenu.Root>
  </DrawerStyled>
);

export default Drawer;
